.PDF {
    background-color: white;
    width: 210mm;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.ExportContainer {
    margin-left: auto;
    text-align: right;
    display: flex;
    justify-content: end;
    margin-bottom:10px;
}