.FooterLink
{
    font-size:18px;
    text-align:center;
}

.FooterLinkMobile {
    font-size: 15px;
    margin-top:10px;
}

.FooterLinks{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    width:50%;
    max-width:700px !important;
    margin-left:auto;
    margin-right:auto;
    margin-top:10px;
}

.FooterLinksMobile {
    width: 90%;
    flex-direction: column !important;
    margin-top:-5px;
}

.FooterContainer {
    width: 100%;
}
.VersionNumber {
    text-align: center;
    margin-top: 15px;
    font-size:15px;
}

.FooterSeparator{
    width:1px;
    background-color:black;
}