.DropDown {
    margin-top: auto;
    margin-bottom: 0;
    margin-left: auto;
    color:black;
    text-decoration:none !important;
}

.DropDown2 {
    margin-top: 0;
    margin-bottom: auto;
    margin-left: auto;
}

.Information {
    background: url('.././Imgs/info.png') no-repeat;
    background-size: 100% 100%;
    width: 11px;
    height: 11px;
    display: block;
    margin-top: -5px;
    margin-left: -2px;
    margin-right: 5px;
    text-decoration: none !important;
}

    .Information:hover {
        background: url('.././Imgs/info-blue.png') no-repeat;
        background-size: 100% 100%;
    }

.InformationMobile {
    background: url('.././Imgs/info.png') no-repeat;
    background-size: 100% 100%;
    width: 11px;
    height: 11px;
    display: block;
    margin-top: -15px;
    margin-left: -140px;
    margin-right: 0px;
    text-decoration: none !important;
    position:absolute;
}

.form-check-input {
    position: static !important;
    margin-top: 0 !important;
    margin-left: 0 !important;
}

.DropdownHover:hover {
    color:rgba(0,162,232,1) !important;
}

    .DropdownHover:hover #Info {
        color: rgba(0,162,232,1) !important;
    }

    .ArcBlue {
        color: rgba(0,162,232,1) !important;
    }

.DropdownHover:hover + a {
    background: url('.././Imgs/info-blue.png') no-repeat;
    background-size: 100% 100%;
}



