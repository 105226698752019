.delete-cross {
    width: 13px;
    height: 13px;
    opacity: 1;
    margin-top:3px;
    margin-left:auto;
}

    .delete-cross:before, .delete-cross:after {
        position:absolute;
        content: ' ';
        height: 13px;
        width: 2px;
        background-color: #333;
    }

    .delete-cross:before {
        transform: rotate(45deg);
    }

    .delete-cross:after {
        transform: rotate(-45deg);
    }

.disabled-delete {
    pointer-events: none;
}

.disabled-delete {
    width: 13px;
    height: 13px;
    opacity: 1;
    margin-top: 3px;
    margin-left: auto;
}

    .disabled-delete:before, .disabled-delete:after {
        position: absolute;
        content: ' ';
        height: 13px;
        width: 2px;
        background-color: #ccc;
    }

    .disabled-delete:before {
        transform: rotate(45deg);
    }

    .disabled-delete:after {
        transform: rotate(-45deg);
    }

.add-cross {
    width: 13px;
    height: 13px;
    opacity: 1;
    margin-top: 6px;
    margin-left: 25px;
    cursor:pointer;
}

    .add-cross:before, .add-cross:after {
        position: absolute;
        content: ' ';
        height: 13px;
        width: 2px;
        background-color: #333;
    }

    .add-cross:before {
        transform: rotate(90deg);
    }

.disabled-add {
    width: 13px;
    height: 13px;
    opacity: 1;
    margin-top: 6px;
    margin-left: 25px;
    cursor: pointer;
    pointer-events:none;
}

    .disabled-add:before, .disabled-add:after {
        position: absolute;
        content: ' ';
        height: 13px;
        width: 2px;
        background-color: #ccc;
    }

    .disabled-add:before {
        transform: rotate(90deg);
    }

.inverter-words{
    margin-right:10px;
    margin-top:3px;
    margin-bottom:auto;
    margin-left:-25px;
    font-family:Arial;
    font-size:80%;
    width:200px;
}

.inverter-string{
    margin-right:20px;
    font-size:12px;
    margin-bottom:0px !important;
}

.inverter-top{
    margin-left:0px !important;
    margin-right:0px !important;
    margin-bottom:5px;
}

.ev-row{
    margin-top:20px;
}

.inverter-select {
    width: 150px;
}

.accessories-container{
    margin-bottom:30px;
    margin-top:30px;
}

.inverter-container{
    margin-top:-23px;
}

.inverter-add {
    font-family: Arial;
    font-size: 80%;
    margin-top: auto;
    margin-bottom: auto;
}

.disabled-accessory {
    color: #ccc !important;
}

.accessory-row {
    margin-left: 0px !important;
    margin-right: 0px !important;
    gap: 40px;
    padding-left: 0px !important;
}

.add-row{
    height:28px;
}

.inverter-row {
    max-width: calc(75vw - 200px);
}

.accessories-title{
    font-size:22px;
}

.AccessoryNumContainer{
    margin-top:5px !important;
}

.EvNumContainer {
    margin-top: -5px !important;
}

.accessories-container-mobile{
    text-align:center;
}

.inverter-mobile-heading{
    width:auto !important;
}

.inverter-mobile-row {
    text-align:center;
    width:100%;
    flex-direction:row;
    justify-content: center;
}

.inverter-add-mobile{
    margin-right:5px;
}

.inverter-add-mobile {
    width: 20px;
    height: 20px;
    opacity: 1;
    margin-top: 3px;
    margin-left: 0px;
    margin-right:10px;
    cursor: pointer;
}

    .inverter-add-mobile:before, .inverter-add-mobile:after {
        position: absolute;
        content: ' ';
        height: 20px;
        width: 2px;
        background-color: #333;
    }

    .inverter-add-mobile:before {
        transform: rotate(90deg);
    }

.disabled-add-mobile {
    width: 20px;
    height: 20px;
    opacity: 1;
    margin-top: 3px;
    margin-left: 0px;
    margin-right: 10px;
    cursor: pointer;
    pointer-events: none;
}

    .disabled-add-mobile:before, .disabled-add-mobile:after {
        position: absolute;
        content: ' ';
        height: 20px;
        width: 2px;
        background-color: #ccc;
    }

    .disabled-add-mobile:before {
        transform: rotate(90deg);
    }

.add-row-mobile{
    margin-top:-10px;
}

.ev-row-mobile{
    margin-top:50px !important;
}

.ev-select-mobile{
    margin-top:-20px;
}