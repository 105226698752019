.horizontal {
    display: flex;
    flex-direction:row;

}

.divForHor {
    display: inline-block;
}

.layout {
    text-align: left;
}


.button {
    background: #E6E7E9 !important;
    color: #E6E7E9 !important;
    border: 0px solid black;
    border-radius: 0px;
    text-transform: uppercase;
    text-align: center;
    font-size: 1px;
}
    .button :hover {
        background-color: #E6E7E9 !important;
        border: 2px solid deepskyblue;
        border-radius: 2px;
    }
    .button :active {
        background-color: #E6E7E9 !important;
        border: 0px solid black;
        border-radius: 0px;
    }
    .button :focus {
        outline: none;
        box-shadow: none;
    }
    .button:disabled {
        opacity: 0.3;
    }
.button2 {
    background: #E6E7E9;
    color: #E6E7E9 !important;
    border: 0px solid black;
    border-radius: 0px;
    text-transform: uppercase;
    text-align: center;
    font-size: 1px;
}
    .button2 :focus {
        outline: none;
        box-shadow: none;
    }
    .button2:disabled {
        background: red !important;
    }

.TotalsContainer {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    padding-right: 86px;
}

.FullTotalContainer
{
    width:847px;
    max-width:847px;
}

.MiniDisplayContainer {
    max-width: fit-content;
}


.LargeDisplayContainer{
    position: absolute;
}

.stop-scrolling {
    width: 100%;
    overflow: hidden;
}